import {Children, cloneElement} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import ColumnWrapper from './ColumnWrapper'
import ColumnItem from './ColumnItem'


const StyledLayout = styled.div`
  display: flex;
  flex-direction: ${({reversed}) => reversed ? 'row-reverse' : 'row'};
  justify-content: space-between;

  ${media.down('mobile')(css`
    flex-direction: ${({reversed}) => reversed ? 'column-reverse' : 'column'};
    align-items: center;
  `)}
`

const Layout = ({type, reversed, children, ...props}) => {
  const childrenArray = Children.toArray(children)

  return (
    <StyledLayout type={type} reversed={reversed} {...props}>
      <For each="child" of={childrenArray} index="idx">
        {cloneElement(child, {type})}
      </For>
    </StyledLayout>
  )
}

Layout.propTypes = {
  type: PropTypes.oneOf(['column', 'asymetric', 'asymetric-reversed', 'asymetric-text']),
  reversed: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  reversed: false,
  type: 'column',
}

Layout.Wrapper = ColumnWrapper
Layout.Item = ColumnItem

export default Layout
