import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media, getVideoID} from '../../../utils'
import {ContentContainer, Typography} from '../../base'
import YTVideo from '../../base/YTVideo/YTVideo'


const StyledExperienceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 7rem 0 6rem;

  ${media.down('mobile')(css`
    margin: 0;
  `)}
`

const StyledText = styled(Typography)`
  position: relative;
  align-self: center;
  transform: translateX(2.025vw);
  z-index: 3;
  text-align: right;
  color: ${({theme}) => theme.colors.textInverted};
  font-size: 8.5vw;

  ${media.down('mobile')(css`
    transform: translateX(2.425vw);
    width: 32vw;
    font-size: 10vw;
  `)}
`

const StyledVideoWrapper = styled.div`
  display: flex;
  position: relative;
  align-content: center;
  width: 100%;

  div {
    width: 100%;
  }

  .lty-playbtn {
    border: 0;
    cursor: pointer;
  }
`

const Experience = ({text, photo, video, ...props}) => {
  return (
    <If condition={photo && video.url && video.title && text}>
      <ContentContainer transparent>
        <StyledExperienceWrapper {...props}>
          <StyledText type="h2" isHeading>{text}</StyledText>
          <StyledVideoWrapper>
            <YTVideo
                videoId={getVideoID(video?.url)}
                buttonTitle={video.title}
                title={text}
                poster={photo?.sourceUrl}
            />
          </StyledVideoWrapper>
        </StyledExperienceWrapper>
      </ContentContainer>
    </If>
  )
}

Experience.propTypes = {
  text: PropTypes.string.isRequired,
  photo: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired,
}

export default Experience
