import {useState} from 'react'
import PropTypes from 'prop-types'
import {noop} from 'lodash-es'
import styled, {css} from 'styled-components'
import {Button} from '../../custom'

// eslint-disable-next-line
const bgImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg=='


const StyledButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Wrapper = styled.div`
  display: block;
  position: relative;
  background-color: black;
  background-image: url(${({poster}) => poster});
  contain: content;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;

  ${({iframe}) => iframe && css`
    z-index: 3;
  `}

  &::before {
    display: block;
    position: absolute;
    top: 0;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    background-image: url(${bgImage});
    background-position: top;
    background-repeat: repeat-x;
    padding-bottom: 50px;
    width: 100%;
    height: 60px;
    content: '';
  }

  &::after {
    display: block;
    padding-bottom: ${({options}) => (options.aspectHeight / options.aspectWidth) * 100}%;
    content: '';
  }
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const YTVideo = ({title, cookie, videoId, buttonTitle, poster, params, options}) => {
  const [preconnected, setPreconnected] = useState(false)
  const [iframe, setIframe] = useState(false)

  const ytUrl = cookie
    ? 'https://www.youtube.com'
    : 'https://www.youtube-nocookie.com'

  const iframeSrc = `${ytUrl}/embed/${videoId}?autoplay=1${params || ''}`

  const warmConnections = () => {
    if (preconnected) return
    setPreconnected(true)
  }

  const addIframe = () => {
    if (iframe) return
    options.onIframeAdded()
    setIframe(true)
  }

  return (
    <>
      <>
        {preconnected && (
          <>
            <link rel="preconnect" href={ytUrl} />
            <link rel="preconnect" href="https://www.google.com" />
            {options.adNetwork && (
              <>
                <link rel="preconnect" href="https://static.doubleclick.net" />
                <link
                    rel="preconnect"
                    href="https://googleads.g.doubleclick.net"
                />
              </>
            )}
          </>
        )}
      </>
      <Wrapper
          iframe={iframe}
          poster={poster}
          options={options}
          onPointerOver={warmConnections}
          onClick={addIframe}
          className={`${iframe && 'active'}`}
          data-title={title || ''}
      >
        <StyledButton
            inverted
            aria-label={`${options.announce} ${title || ''}`}
        >
          {buttonTitle}
        </StyledButton>
        {iframe && (
          <StyledIframe
              title={title || ''}
              width="560"
              height="315"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              src={iframeSrc}
          />
        )}
      </Wrapper>
    </>
  )
}

YTVideo.propTypes = {
  title: PropTypes.string,
  cookie: PropTypes.bool,
  videoId: PropTypes.string,
  buttonTitle: PropTypes.string,
  poster: PropTypes.string,
  params: PropTypes.string,
  options: PropTypes.shape({
    announce: PropTypes.string,
    adNetwork: PropTypes.bool,
    aspectWidth: PropTypes.number,
    aspectHeight: PropTypes.number,
    onIframeAdded: PropTypes.func,
  }),
}

YTVideo.defaultProps = {
  cookie: true,
  options: {
    announce: 'Watch',
    adNetwork: false,
    aspectWidth: 16,
    aspectHeight: 9,
    onIframeAdded: noop,
  },
}

export default YTVideo
