
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {media} from '../../../utils'
import {Layout} from '../../base'
import BoxTitle from './BoxTitle'
import BoxDescription from './BoxDescription'
import BoxImage from './BoxImage'


const StyledContentContainer = styled.div`
  display: grid;
  place-items: baseline;
  grid-template-rows: 4rem 1fr auto;
  margin-left: ${({reversed}) => reversed ? 'auto' : 0};
  width: 66%;
  height: 100%;

  ${({title}) => isEmpty(title) && css`
    grid-template-rows: auto;
    align-content: center;
  `}

  ${media.down('mobile')(css`
    width: 100%;
  `)}
`

const StyledImageWrapper = styled(Layout.Wrapper)`
  flex-grow: 0;
  margin: 0 auto;
  width: 35rem;

  ${media.down('mobile')(css`
    width: 100%;
  `)}
`

const StyledTextItem = styled(Layout.Wrapper)`
  max-width: 50%;

  ${media.down('mobile')(css`
    max-width: 100%;
  `)}
`

const Box = ({title, image, description, footer, imageButton, reversed, ...props}) => (
  <Layout as="article" reversed={reversed} {...props}>
    <If condition={!isEmpty(image)}>
      <StyledImageWrapper noPadding>
        <Layout.Item>
          <BoxImage image={image}>
            {imageButton}
          </BoxImage>
        </Layout.Item>
      </StyledImageWrapper>
    </If>
    <StyledTextItem noPadding>
      <Layout.Item>
        <StyledContentContainer reversed={reversed} title={title}>
          <If condition={!isEmpty(title)}>
            <BoxTitle>{title}</BoxTitle>
          </If>
          <BoxDescription title={title}>{description}</BoxDescription>
          {footer}
        </StyledContentContainer>
      </Layout.Item>
    </StyledTextItem>
  </Layout>
)

Box.defaultProps = {
  title: '',
  image: {},
  description: '',
  footer: '',
}

Box.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  description: PropTypes.string,
  footer: PropTypes.node,
  imageButton: PropTypes.node,
  reversed: PropTypes.bool,
}

export default Box
