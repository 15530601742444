
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledWrapper = styled.div`
  display: flex;

  ${media.down('mobile')(css`
    margin: 0 auto;
  `)}
`

const SyledBox = styled.span`
  transition: ${({theme}) => (`
    opacity ${theme.global.duration} ease-in,
    background-color ${theme.global.duration} ease-in
  `)};
  border: ${({theme}) => `1px solid ${theme.colors.primary}`};
  cursor: pointer;
  padding: 0.5rem;

  &:not(:first-child) {
    margin-left: 1rem;
  }

  &:hover {
    opacity: 0.7;
    background-color: ${({theme}) => theme.colors.primary};
  }

  ${media.down('tablet')(css`
    padding: 0.75rem;
  `)}

  ${media.down('mobile')(css`
    margin-top: 0.75rem;
  `)}

  ${({active}) => active && css`
    background-color: ${({theme}) => theme.colors.primary}
  `}
`

const BoxStepper = ({items, currentIndex, handleClick}) => (
  <StyledWrapper>
    <For each="item" of={items} index="idx">
      <SyledBox key={idx} active={currentIndex === idx} onClick={handleClick(idx)} />
    </For>
  </StyledWrapper>
)

BoxStepper.propTypes = {
  items: PropTypes.array.isRequired,
  currentIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default BoxStepper
