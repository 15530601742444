import PropTypes from 'prop-types'
import {lazyAfterLoad} from '../../../utils'
import Typography from '../Typography/Typography'


export const DefaultWrapper = ({children}) => (
  <Typography type="div">{children}</Typography>
)

DefaultWrapper.propTypes = {
  children: PropTypes.node,
}

export default lazyAfterLoad(() => import(/* webpackChunkName: "wpContent" */ './WpContent'))
