
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media, checkUrl} from '../../../utils'
import {Typography} from '../../base'
import Button from '../Button/Button'


const HeroContainer = styled.div`
  background-image: url(${({url}) => url});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30rem;

  ${media.down('tablet')(css`
    height: 30rem;
  `)}

  ${media.down('mobile')(css`
    height: 25rem;
  `)}
`

const HeroFilter = styled.div`
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
`

const StyledTitle = styled(Typography)`
  padding-top: 14.5rem;
  text-align: center;
  color: ${({theme}) => theme.colors.textInverted};

  ${media.down('tablet')(css`
    padding-top: 12rem;
  `)}

  ${media.down('mobile')(css`
    padding-top: 10.5rem;
  `)}
`

const StyledButton = styled(Button)`
  position: relative;
  top: -1.875rem;
  left: 50%;
  transform: translateX(-50%);
  width: 14.75rem;

  ${media.down('mobile')(css`
    top: -1.5rem;
  `)}
`

const Hero = ({url, title, to, buttonTitle}) => (
  <HeroContainer url={checkUrl(url)}>
    <HeroFilter>
      <StyledTitle type="h1" isHeading>
        {title}
      </StyledTitle>
    </HeroFilter>
    <If condition={buttonTitle && to}>
      <StyledButton to={to}>
        {buttonTitle}
      </StyledButton>
    </If>
  </HeroContainer>
)

Hero.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  buttonTitle: PropTypes.string,
}

export default Hero
