
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const applyType = {
  'asymetric': css`
    &:first-child {
      flex-shrink: 2;
    }
  `,
  'asymetric-reversed': css`
    &:first-child {
      flex-shrink: 0.5;
    }
  `,
  'asymetric-text': css`
    &:first-child {
      flex-shrink: 3.5;
    }
  `,
}

const StyledColumnWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 2;
  padding: ${({noPadding}) => noPadding ? '0' : '0 0.5rem'};
  width: 100%;

  ${media.down('mobile')(css`
    justify-content: center;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  `)}

  ${({type}) => applyType[type]}
`

const ColumnWrapper = ({type, noPadding, children, ...props}) => (
  <StyledColumnWrapper type={type} noPadding={noPadding} {...props}>
    {children}
  </StyledColumnWrapper>
)

ColumnWrapper.propTypes = {
  type: PropTypes.oneOf(['column', 'asymetric', 'asymetric-reversed', 'asymetric-text']),
  noPadding: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default ColumnWrapper
