
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {Typography} from '../../base'


const StyledDescription = styled(Typography)`
  margin-top: 1rem;
  padding-bottom: 2rem;

  ${({title}) => isEmpty(title) && css`
    margin-top: 0;
  `}
`

const BoxDescription = ({title, children, ...props}) => (
  <StyledDescription type="div" title={title} {...props}>{children}</StyledDescription>
)

BoxDescription.propTypes = {
  children: PropTypes.string,
  title: PropTypes.string,
}

export default BoxDescription
