
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash-es'
import styled, {css} from 'styled-components'
import {Typography} from '../../base'


const StyledTitle = styled(Typography)`
  position: relative;
  margin-bottom: 1rem;

  ${({title}) => !isEmpty(title) && css`
    &::before {
      position: absolute;
      bottom: calc(-1rem - 50% - 3px);
      border-bottom: 3px solid ${({theme}) => theme.colors.primary};
      width: 2rem;
      content: '';
    }
  `}
`

const BoxTitle = ({children, ...props}) => (
  <StyledTitle type="h2" isHeading title={children} {...props}>{children}</StyledTitle>
)

BoxTitle.propTypes = {
  children: PropTypes.string.isRequired,
}

export default BoxTitle
