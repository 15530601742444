
import {Children} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import Image from '../../base/Image/Image'


const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  line-height: 0;

  ${media.down('mobile')(css`
    margin-bottom: 1rem;
  `)}
`
const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`

const StyledImageButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-right: inherit;
`

const BoxImage = ({image, children, ...props}) => (
  <StyledImageWrapper {...props}>
    <StyledImage loading="lazy" url={image?.srcSet ? image?.srcSet : image?.sourceUrl} alt={image?.altText} />
    <If condition={Children.count(children) > 0}>
      <StyledImageButton>
        {children}
      </StyledImageButton>
    </If>
  </StyledImageWrapper>
)

BoxImage.propTypes = {
  image: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default BoxImage
