
import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledColumnItem = styled.div`
  height: 100%;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`

const ColumnItem = ({children, ...props}) => (
  <StyledColumnItem {...props}>
    {children}
  </StyledColumnItem>
)

ColumnItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ColumnItem
